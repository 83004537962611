import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'

export default class HomeAccordion{
    constructor(el){
        this.el = el
        this.index = qsa('.index', this.el)
        this.items = qsa('.home_accordion_item', this.el)
        this.staggers = qsa('[stagger]', this.el)
        this.active = 0
        this.duration = .4
    }
    init(){
        this.setOpacity()
        this.setPosition()
        this.index.forEach((el,i) => {
            el.addEventListener('click',(e) => {
                if (i != this.active) {
                    this.modal(e,i)
                }
                
            })
        })
        window.addEventListener('resize', () => {
            this.setPosition()
        })
    }
    setOpacity(){
        const content = this.items[0].querySelector('.content')
        this.items[0].classList.add('active')
        content.classList.add('active')
        gsap.set(content,{
            opacity: 1
        })
        this.setPosition()
    }
    setPosition() {
        if (window.innerWidth > 640) {
            this.items.forEach((item,i) => {
                if (i == this.active) {
                    gsap.set(item,{
                        height: 'auto',
                        flexBasis: '80%'
                    })  
                } else {
                    gsap.set(item,{
                        height: 'auto',
                        flexBasis: '5%'
                    })                      
                }
            })
        } else {
            this.items.forEach((item,i) => {
                if (i == this.active) {
                    gsap.set(item,{
                        height: 'auto',
                        flexBasis: '100%'
                    })  
                } else {
                    gsap.set(item,{
                        height: '32px',
                        flexBasis: '100%'
                    })                      
                }
            })
          
        }
    }
    modal(event,index) {
        this.active = index
        const trigger = event.target.parentNode
        this.items.forEach((item) => {
            if (trigger == item) {
                item.classList.add('active')
                const content = qs('.content',item)
                content.classList.add('active')
                if (window.innerWidth > 640) {
                    const tl = gsap.timeline()
                    /* DESKTOP ANIMATION */
                    tl.set(content,{
                        opacity: 1
                    })
                    .to(item,{
                        flexBasis: '80%',
                        duration: this.duration,
                        delay: this.duration                
                    })
    
                    .to(this.staggers,{
                        x: 0,
                        opacity: 1
                    })
                } else {
                    const tl = gsap.timeline()
                    /* DEVICE ANIMATION */
                    tl.set(content,{
                        opacity: 1
                    })
                    .to(item,{
                        height: 'auto',
                        duration: this.duration,
                        delay: this.duration                
                    })
    
                    .to(this.staggers,{
                        x: 0,
                        opacity: 1
                    })
                }

            } else {
                const content = qs('.content',item)
                if (window.innerWidth > 640) {
                    const tl = gsap.timeline()
                    /* DESKTOP ANIMATION */
                    tl.to(this.staggers,{
                        opacity: 0,
                        x: 10,
                        duration: this.duration
                    }) 
                    .to(item,{
                        flexBasis: '5%',
                        duration: this.duration                  
                    }) 
                    .set(content,{
                        opacity: 0,
                        onComplete: () => {
                            item.classList.remove('active')
                        }
                    })
                } else {
                    const tl = gsap.timeline()
                    /* DEVICE ANIMATION */
                    tl.to(this.staggers,{
                        opacity: 0,
                        x: 10,
                        duration: this.duration
                    }) 
                    .to(item,{
                        height: '32px',
                        duration: this.duration                  
                    }) 
                    .set(content,{
                        opacity: 0,
                        onComplete: () => {
                            item.classList.remove('active')
                        }
                    })
                }
              
            }
        })
    }
}
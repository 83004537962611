import {qs,qsa} from '../utils/lib'
export default class MagazineNotes {
    constructor(el){
        this.el = el
        this.highlighted = qs('mark', this.el)
        this.note = qs('.magazine_article_text_notes', this.el)
    }
    init(){

        if (this.highlighted) {
            this.setNotePosition()
        }
        
    }
    setNotePosition(){
        const top_pos = this.highlighted.offsetTop
        this.note.style.top = `${top_pos}px`
    }
}
import { qs, qsa } from '../utils/lib'
import { gsap } from 'gsap'

export default class RegularCarousel {
    constructor(el) {
        this.el = el
        this.next = this.el.querySelector('.next');
        this.prev = this.el.querySelector('.prev');
        this.status = 0;
        this.active_slide = 0;
        this.slides = qsa('.regularCarousel_carousel_single', this.el);
        this.margin = 40
        this.isAnimating = false;
        this.slideWidth = this.el.querySelector('.regularCarousel_carousel_single').offsetWidth + this.margin
        this.track = this.el.querySelector('.regularCarousel_carousel_track')
        this.arrows = this.el.querySelector('.regularCarousel_carousel_single_btn')
        this.centered = this.track.classList.contains('centered')
    }
    init() {
        this.setOpacity()
        this.next.addEventListener('click', () => this.goNext());
        this.prev.addEventListener('click', () => this.goPrev());
        this.active_slide = this.status

    }
    setPosition() {
        const position = this.track.offsetWidth * 0.125
        gsap.set([this.track, this.arrows], {
            x: position
        })

    }
    goNext() {

        if (this.isAnimating) {
            return false

        } else {
            this.status++;

            this.isAnimating = true

            gsap.to(this.track, {
                duration: 0.5,
                x: `-=${this.slideWidth}`,
                onComplete: () => {
                    this.isAnimating = false

                }
            })

            this.checkStatus()

            this.active_slide = this.status

            this.setOpacity()
            this.slides[this.status - 1].classList.remove('active')
            this.slides[this.status - 1].classList.add('opacity')
        }



    }
    goPrev() {
        if (this.isAnimating) {
            return false

        } else {
            this.isAnimating = true

            this.status--;

            gsap.to(this.track, {
                duration: 0.5,
                x: `+=${this.slideWidth}`,
                onComplete: () => {
                    this.isAnimating = false
                    console.log(this.isAnimating)
                }
            })

            this.checkStatus()


            this.active_slide = this.status

            this.setOpacity()
            this.slides[this.status + 1].classList.remove('active')
            this.slides[this.status + 1].classList.add('opacity')
        }


    }
    setOpacity() {

        if (this.active_slide === this.status) {
            this.slides[this.status].classList.remove('opacity')
            this.slides[this.status].classList.add('active')
        }

    }
    checkStatus() {
        if (this.status >= this.slides.length - 1) {
            this.next.classList.add('disabled');
        } else {
            this.next.classList.remove('disabled');

        }

        if (this.status > 0) {
            this.prev.classList.remove('disabled');

        } else {
            this.prev.classList.add('disabled');

        }
    }

}


import Tabs from '../components/tabs'
import "@kollegorna/cocoon-vanilla-js"
import '@toast-ui/editor/dist/toastui-editor.css'; 
import Editor from '@toast-ui/editor';
import Sortable from 'sortablejs';
import Selectr from "mobius1-selectr"
import { qs, qsa } from '../utils/lib'
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;
import "dropzone/dist/dropzone.css";

export default class PrivateArea{
    constructor(){
        this.dropzones = qsa(".dropzone")
        this.init()
    }

    init() {
       
        const notice = qs('.private_area_notice')

        if ( notice) {
            window.setTimeout(function(){
                window.scrollTo(0,0)
            }, 500)
            
        }
        document.addEventListener('cocoon:after-insert', (e, insertedItem, originalEvent) => {
            const wrapper = qs(".multiple_form")
            const els = qsa('select',wrapper)
            els.forEach((el) => {
                new Selectr(el,{
                    defaultSelected: false,
                    searchable: true,
                    placeholder: el.getAttribute("placeholder")
                });
            })
            
        })

        this.dropzones.forEach((el) => {
            const dz = new Dropzone(el, { url: el.dataset.action});
            dz.on("success", file => {  
                const r = file.xhr.response
                const cont = qs(el.dataset.target)
                cont.classList.add("updated")
                //const dragel = qs('.js_image_ordering')
                //const clone = dragel.cloneNode(true)
                //clone.classList.remove("hidden")
                //clone.querySelector("img").setAttribute("src",r)
                //cont.appendChild(clone)
            });
        })

        const image_deleters = qsa('.image_remove_fields')
        image_deleters.forEach((deleter) => {
            deleter.addEventListener("click",(e) => {
                e.preventDefault()
                e.target.querySelector("input").value = "1"
                e.target.parentNode.classList.add("hidden")
            })
        })

        const positions = qsa('.js_draggable')
        positions.forEach((p) => {
            const sortable = new Sortable(p, {
                scroll: false, // Enable the plugin. Can be HTMLElement.
                multiDrag: true, // Enable the plugin
                multiDragKey: 'ALT',
                selectedClass: "sortable-selected",
                handle: ".col-sortable",
                onEnd: function (e) {
                    const els = e.target.querySelectorAll('.col-sortable')
                    els.forEach((el,i) => {
                        const input = el.querySelector('input')
                        if ( input ) {
                            input.value = i
                        }
                    })
                },
                
            });
        })
    
        
        const textareas = document.querySelectorAll('.editor_js')
        textareas.forEach((text) => {
            let toolbar;
            if ( text.classList.contains('editor_list') ) {
                toolbar = [
                    ['link']
                ]
            } else {
                toolbar = [
                    ['heading','italic','bold'],
                    ['ul'],
                    ['link']
                ]
            }
            const editor = new Editor({
                el: text,
                height: '500px',
                initialEditType: 'wysiwyg',
                initialValue: text.dataset.content,
                toolbarItems: toolbar,
                hideModeSwitch: true,
                events: {
                    change: update_editor
                }
            });
            function update_editor() {
                qs(text.dataset.target).value = editor.getMarkdown()
            }
        })
        const tabs = qsa('.js_tabs')
        tabs.forEach((t) => {
            const tb = new Tabs(t)
            tb.init()
        })
    }
}
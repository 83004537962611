import { qs,qsa } from "../utils/lib"
import gsap from 'gsap'


export default function() {

    const page = document.querySelector('.labs_hero_video_container')
    window.addEventListener('touchstart', {})
    var cookie_privacy = true
    const getCookieValue = (name) => (
        document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    )

    const privacy_cookie = getCookieValue("_iub_cs-29398167")

    if ( privacy_cookie ) {
        const value = JSON.parse(decodeURIComponent(privacy_cookie))
        cookie_privacy = value.purposes[4]
    }
    if (page) {
        if ( cookie_privacy == true ) {
            var tag = document.createElement('script');
         tag.src = "//www.youtube.com/iframe_api";
         var firstScriptTag = document.getElementsByTagName('script')[0];
         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        const videos = Array.from(document.querySelectorAll('.js-labs-hero'))
        videos.forEach(function(video){
            var checkYT = setInterval(function () {
                if(YT.loaded){
                	const id = video.querySelector('.player_div').getAttribute("id")
                    player = new YT.Player(id, {
                      videoId: video.getAttribute("data-video"),
                      rel: 0,
                      title: video.getAttribute("data-alt"),
                      events: {
                        'onStateChange': onPlayerStateChange
                      }
                    })

                    const breadcrumbs = video.querySelector('.labs_hero_video_container_breadcrumbs')
                    const btn = video.querySelector('.labs_hero_video_container_btn')
                    const txt = video.querySelector('.labs_hero_video_container_title')
                    btn.addEventListener("click",() => {
                        gsap.to(breadcrumbs,.2,{
                            opacity: 0,
                            onComplete: () => {
                                gsap.set(breadcrumbs, {
                                    display: 'none'
                                })
                            }
                        })
                        gsap.to(btn,.2,{
                            opacity: 0,
                            scale: 0.5,
                            onComplete: () => {
                                gsap.set(btn, {
                                    display: 'none'
                                })
                            }
                        })
                        const iframe = video.querySelector('iframe')
                        iframe.classList.add('active')
                        if(window.innerWidth > 640) {
                            gsap.to(txt,.2,{
                                opacity: 0,
                                y:16,
                                onComplete: () => {
                                gsap.set(txt, {
                                    display: 'none'
                                })
                            }
                            })
                        }
                        player.playVideo()
                    })

                        clearInterval(checkYT);
                }
            }, 100);
              let player;
              window.onPlayerStateChange = function(event) {
                if (event.data == YT.PlayerState.ENDED) {
                    player.stopVideo();
                    }
                };
              }
             

    )
        } else {
            const videos = Array.from(document.querySelectorAll('.js-labs-hero'))
            videos.forEach(function(video){
                const id = video.querySelector('.labs_hero_video_container_btn').dataset.video_id
                const btn = video.querySelector('.labs_hero_video_container_btn')
                btn.addEventListener("click",() => {
                    const URL = "https://www.youtube.com/watch?v=" + id 
                    window.open(URL, '_blank');
                })

            })
            return false
        }
    	 }
}
import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
import smoothscroll from 'smoothscroll-polyfill';

export default class Sidebar{
    constructor(el){
        this.el = el
        this.trigger = qs('.js-sidebar-trigger')
        this.numbers = qsa('.sidebar_inner_list_item_index', this.el)
        this.els = qsa("[data-scroll]", this.el)
        this.offset_header = qs('.header_wrap').offsetHeight
        this.color_pr = '#a9e44c' 
        this.title_hero = qs('.js-sidebar-start')
        this.container = qs('.container')
    }
    init(){
        smoothscroll.polyfill();
        this.els.forEach((el) => {
            el.addEventListener('click', this.anchor.bind(this,el))
        })       
    }
    setInitialPosition(){
        
    }
    changeColor(){
       
        
    }
    anchor(el){
        const t = document.querySelector(`#${el.dataset.target}`)
        const coords_y = window.pageYOffset + t.getBoundingClientRect().top - this.offset_header * 2
        window.scrollTo({
            top: coords_y,
            behavior: 'smooth'
        });
    }
}
import gsap from 'gsap/gsap-core'
import {qs,qsa} from '../utils/lib'
export default class Menu{
    constructor(el) {
        this.el = el
        this.cta_menu_open = qs('.menu_icon_open')
        this.cta_menu_close = qs('.menu_icon_close')
        this.duration = .5
        this.animating = false
        this.html = qs('html')
        this.body = qs('body')
        this.menu = qs('.menu_wrap', this.el)
        this.layer = qs('.menu_layer')
    }
    init(){
        if (this.cta_menu_open) {
            if (this.animating) {
                return
            } else {
                this.cta_menu_open.addEventListener('click', this.animate.bind(this,-1))
            } 
        }
        if (this.cta_menu_close) {
            if (this.animating) {
                return
            } else {
                this.cta_menu_close.addEventListener('click', this.animate.bind(this,1))
            } 
        }    
        this.layer.addEventListener('click',(e) => {
            if (e.target != this.menu) {
                this.animate(1)
            }
        })       
    }
    animate(dir){
        if (dir < 0) {
            this.layer.classList.add('open')
        } else {
            this.layer.classList.remove('open')
        }
        this.animating = true
        gsap.to(this.el,{
            x: `+=${dir * 100}%`,
            duration: this.duration,
            ease: 'EaseInOut',
            onComplete: () => {
                this.animating = false
                if (dir < 0) {
                    this.el.classList.add('open')
                    this.html.classList.add('overflow')
                    this.open = true
                } else {
                    this.html.classList.remove('overflow')
                    this.el.classList.remove('open')
                    this.open = false
                    this.layer.classList.remove('open')
                }
            }
        })
    }
}
import React, {useState, useEffect} from 'react'
import {qs, qsa, fetchJSON} from '../utils/lib'
import gsap from 'gsap'

const Search = () => {
    
    const search_wrapper = qs('.jsx-search')
    const search_btns = qsa('.js-search')
    const [array, setArray] = useState([])
    const [input_length, setInputLength] = useState(0)
    const lang = search_wrapper.dataset.lang
    useEffect(() => {
        search_btns.forEach((btn) => {
            btn.addEventListener('click', () => {
                if (!search_wrapper.classList.contains('open')) {
                    openSearch()
                } else {
                    closeSearch()
                }
            })
        })

    },[])
    const searchItem = async (props) => {
        setInputLength(props.target.value.length)
        if (props.target.value == "" || props.target.value.length < 3 ) {
            setArray([])
        } else {
            const data = await fetchJSON('/it/search.json?q=' + props.target.value)
           
            setArray(data)
        }
    }
    const openSearch = () => {
        search_wrapper.classList.add('open')
        const input = search_wrapper.querySelector('input')
        input.focus()
        gsap.fromTo(search_wrapper,{
            opacity: 0
        },{
            opacity: 1,
            duration: .5
        })

    }
    const closeSearch = () => {
        gsap.to(search_wrapper,{
            opacity: 0,
            duration: .5,
            onComplete: () => {
                search_wrapper.classList.remove('open')  
                const input = qs('input', search_wrapper)
                input.value = ""
                setArray([])
                setInputLength(0)                
            }
        })   
    }
    const ResearchedItems = () => {
        if (array.length > 0 && input_length > 0) {
            return (
                <div className="bg-dark search_container">
                    {array.map((el,i) => (        
                        <a href={el.link} className="search_col_results c-light" key={i}>
                            <p className="c-light medium h9">{el.title}</p>
                            <p className="c-light medium h8">{el.name}</p>
                        </a>
                    ))}  
                </div>                
            )
        } else if (array.length == 0 && input_length == 0) {
            return (
                <>
                </>
            )
        } else {
            return (
                <div className="bg-dark ">
                    <div className="search_col_results">
                        <h1 className="c-light h7 medium">{lang == 'it' ? 'Nessun risultato' : 'No results'}</h1>
                    </div>
                </div>                 
            )
        }
    }
    return (
        <div className="row no-margin v-center bg-dark">
            <div className="container">
                <div className="row no-margin v-center bg-dark inner">
                    <div className="search_col text-center">
                        <span className="icon-DELETEX c-light h8" onClick={closeSearch}></span>
                    </div>
                    <div className="search_col_input">
                        <input type="text" className=" c-light medium h7" placeholder={lang == 'it' ? 'Cerca nel sito' : 'Find in website'} onChange={searchItem} />
                    </div>
                </div>
                <ResearchedItems />
            </div>
        </div>
    )
}

export default Search;
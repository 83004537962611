import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'

export default class ProjectDetailsAccordion{
	constructor(el){
		this.el = el
		this.headers = qsa(".js_header", this.el)
		this.bodys = qsa(".js_body", this.el)
	}

	init() {
		this.headers.forEach((head,i) => {
            head.addEventListener('click',(e) => {
                this.showActiveBody(i)
            })
        })
	}

	showActiveBody(i) {
		let active_head = i
		let active_body = active_head
		this.headers[active_head].classList.toggle("active")
		this.bodys[active_body].classList.toggle("active")
	}
}
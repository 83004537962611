import gsap from 'gsap'

function setAnimation(el) {
	setFade(el)
	setRotate(el)
}

function setFade(el) {
	const text = el.querySelector(".js-animation-text")
	const image =el.querySelector(".js-animation-image")
	gsap.to(el, {
		opacity: 0,
		duration: 0
	})
}

function setRotate(el) {
	gsap.to(el, {
		opacity: 0,
		duration: 0,
		rotateY: el.dataset.fromrotatey || 0,
		rotateX: el.dataset.fromrotatex || 0,
	})
}




function animationControl(animation_type, target) {

	switch (animation_type) {
		case "fade":
			fadeAnimation(target)
			break;
		case "rotate":
			rotateAnimation(target)
			break;
		case "imageClip":
			imageClip(target)
		default:
	}
}
// js-animation data-animation="[fade, rotate]"
/**
* Per il Fade
* data-opacity="[value]"
* data-duration="[value]"
*
*/
/** 
* Per il Rotate
* data-rotatex="[value]"
* data-rotatey="[value]"
* data-opacity="[value]"
* data-duration="[value]"
*/

function fadeAnimation(el) {
	const text = el.querySelector(".js-animation-text")
	const image = el.querySelector(".js-animation-image")
	const tl= gsap.timeline()
	tl.to(text, {
		y:"20%",
		duration: 0
	})
	tl.to(image, {
		scale: 1.2,
		duration: 0
	})
	tl.to(text, {
		y: 0,
		ease: "Power2.out",
		duration: 1.3
	},"same")
	tl.to(image, {
		scale: 1,
		ease: "Power2.out",
		duration: 1.3
	},"same")
	tl.to(el, {
		opacity: el.dataset.opacity || 1,
		duration: el.dataset.duration || 1,
	},">-.6")
	
}

function rotateAnimation(el) {
	gsap.to(el, {
		opacity: el.dataset.opacity || 1,
		duration: el.dataset.duration || 1.5,
		rotateY: el.dataset.rotatey || 0,
		rotateX: el.dataset.rotatex || 0
	})
}

function imageClip(el) {
	gsap.fromTo(el, {
		opacity: 0,
		clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)"
	}, {
		duration: 1.5,
		opacity: 1,
		clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
	});
}

export { animationControl, setAnimation }


import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
import hoverintent from 'hoverintent'
export default class Header {
    constructor(el){
        this.el = el
        this.header_links = qsa('.header_wrap_list_item', this.el)
        this.layer = qs('.header_wrap_dropdown_layer', this.el)
        this.dropdown = qs('.header_wrap_dropdown', this.el)
        this.dropdown_rows = qsa('.header_wrap_dropdown_row_wrap', this.el)
        this.foreground = qs('.header_wrap_foreground')
        this.isOpen = false
        this.duration = .2
        this.height = this.dropdown.offsetHeight
        this.list = this.el.querySelector('.header_row')
        this.header_int_links = qsa('.link_internal, .js-search, .pill, .dropdown_user', this.list)
        this.logo = qs('.header_wrap_logo > img', this.el)
        this.active = ''
        this.user_dropdown_btn = qs('.dropdown_user', this.el)
        this.src = this.logo.src
        this.page = this.el.dataset.page
    }
    init(){
        this.setHeightDropdown()
        this.header_links.forEach((item,i) => {
            hoverintent(item,
                () => {
                    i !== this.active && this.changeHeaderForeground(i)
                },
                () => {
                }
            ) 
        })
        this.header_int_links.forEach((item,i) => {
            hoverintent(item,
                () => {
                    this.resetHeaderForeground()
                    this.dropdown.classList.contains('open') && this.closeDropdown()
                },
                () => this.resetHeaderForeground()
            )             
        })
        this.el.addEventListener('mouseleave', () => {
            this.resetHeaderForeground()
            this.dropdown.classList.contains('open') && this.closeDropdown()
        })
        // this.user_dropdown_btn.addEventListener('click', () => {
        //    qs('.js-dropdown-user').classList.toggle('open')
        // })
    }   
    setHeightDropdown(){
        gsap.set(this.dropdown,{
            height: 0
        })
    }
    changeHeaderForeground(index){
        this.foreground.classList.add('modal_open')
        this.openDropdown(index)
    }
    resetHeaderForeground(){
        this.hideItems()
        this.foreground.classList.remove('modal_open')
    }
    openDropdown(index){
        this.active = index
        this.isOpen = true
        this.dropdown.classList.add('open')
        this.header_links[this.active].classList.add("active")
        this.dropdown_rows.forEach((row,i) => {
            if (i == index) {
                row.classList.add('active')
                this.height = row.offsetHeight
            } else {
                this.header_links[i].classList.remove("active")
                row.classList.remove('active')
                const links = qsa('a',row)
                gsap.set(links,{
                    opacity: 0
                })                 

            }
        })        
        gsap.set(this.logo,{
            attr: {
                src: this.page == 'blogs' ? this.logo.src : this.logo.src.replace('verdi','bianchi')
            }
        })
        gsap.to(this.dropdown,{
            height: this.height,
            duration: this.duration,
            onComplete:() => {
                this.showItems(index)

            }
        })
    }
    showItems(index){
        const links = qsa('a',this.dropdown_rows[index])
        gsap.fromTo(links,{
                opacity: 0
            },{
                opacity:1,
                duration: this.duration
        })
    }
    hideItems() {
        this.dropdown_rows.forEach((row) => {
            row.classList.remove('active')
            const links = qsa('a',row)
            gsap.set(links,{
                opacity: 0
            })          
        })
    }
    closeDropdown(){
        this.header_links[this.active].classList.remove("active")
        this.active = ''
        this.isOpen = false 
        gsap.set(this.logo,{
            attr: {
                src: this.src
            }
        })        
        gsap.to(this.dropdown,{
            height: 0,
            duration: this.duration,
            onComplete: () => {
                this.foreground.classList.remove('is-open') 
                this.dropdown.classList.remove('open')
            }
        })          
    }
}
import { qs,qsa } from "../utils/lib"
const BlankLinks = () => {
    const links = qsa('a',document)
    links.forEach((link) => {
        const child = link.firstChild
        if (child) {
            child.textContent.includes('↗') && link.setAttribute('target', '_blank')
        } else {
            link.textContent.includes('↗') && link.setAttribute('target', '_blank')
        }
        
        
        
    })
}
export default BlankLinks
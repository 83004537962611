import {qs,qsa} from '../utils/lib'

export default class Tabs{
    constructor(el){
        this.active = 0
        this.el = el
        this.headers = qsa('.js_tabs_headers', el)
        this.targets = qsa('.js_tabs_target', el)
    }
    init(){
        this.setEl()
        this.headers.forEach((el,i) => {
            el.addEventListener("click", () => {
                this.active = i
                this.setEl()
            })
        })
    }

    setEl() {
        this.reset()
        this.headers[this.active].classList.add('active')
        this.targets[this.active].classList.add('active')
    }

    reset() {
        this.headers.forEach((el) => {
            el.classList.remove('active')
        })
        this.targets.forEach((el) => {
            el.classList.remove('active')
            el.classList.add('hidden')
        })
    }
}
import { qs, qsa } from '../utils/lib'
import gsap from 'gsap'

import { animationControl, setAnimation } from '../components/observer_animations.js'

export default class IntersectionObs {
	constructor() {
		this.target = qsa(".js-animation")
		this.options = {
			rootMargin: '0px 0px 100px 0px',
			threshold: 0.50
		}
	}

	init() {
		this.target.forEach((el) => {
			setAnimation(el)
		})
		this.initObserver()
	}

	animation(entries) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				animationControl(entry.target.dataset.animation, entry.target)
				this.observer.unobserve(entry.target)
			}
		})

	}

	initObserver() {
		this.observer = new IntersectionObserver(this.animation.bind(this), this.options)
		this.target.forEach((el) => {
			this.observer.observe(el)
		})

	}

}